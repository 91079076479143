.karusell {
  cursor: grab;
  position: relative;
  display: flex;
  justify-content: center;
}

.karusell::before,
.karusell::after {
  z-index: 2;
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 800vw;
  height: 800vw;
  background: #131313;
  border-radius: 50%;
}

.karusell::before {
  top: -795vw;
}

.karusell::after {
  bottom: -795vw;
}

@media screen and (max-width: 1324px) {
  .karusell {
    width: 100%;
    right: 2%;
    margin: 0 auto;
    transform: scale(0.7); /* Add transform property to scale the carousel */
  }
  .karusell::before {
    top: -795vw;
  }
  
  .karusell::after {
    bottom: -795vw;
  }
}

@media screen and (max-width: 950px) {
  .karusell {
    display: none !important;
  }
}