.modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    
  }
  
  .modal-content {
    display: block;
    margin: auto;
    width: 80%;
    max-width: 800px;
    max-height: 95%;
    overflow: hidden;
    background-color: #f2f2f2;
    
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    position: relative;
    
  }
  
  
  .modal-image {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
  
  .close-image {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .close:hover {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
  }

  .prev-button {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 12px;
    cursor: pointer;
  }
  
  .next-button {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 12px;
    cursor: pointer;
  }