.booking{
    margin:10rem 10rem;
    min-height: 100vh;
}

.booking p {
    color: #f1f1f1;
    font-size: large;
}
.booking strong{
    color: #f1f1f1;
    font-size: large;
}

.BokningRubrik{
    color: #f1f1f1;
}

.Link:hover {
    color: var(--color-primary);
    text-decoration: underline;
  }

.Bokning {
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.Steg {
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.2rem;
}

.Information {
    width: 45%;
    text-align: start;
    font-size: 1.1rem;
}
.Information h2{
    padding-bottom: 1rem;
}

.Information a{
    color:var(--color-primary);

}
.Information a:hover{
    color: var(--color-primary);
    text-decoration: underline;

}
.Information p{
    margin-bottom: 1em;
    
}

.InfoSteg {
    width: 45%;
    text-align: start;
    font-size: 1.1rem;
}
.InfoSteg h2{
    padding-bottom: 1rem;
}

.lista li{
   font-size: 1.3rem;
   padding: 5px;
   color: #f1f1f1;
}

.Information p {
    color: #f1f1f1;
}
.bold-underline {
    font-weight: bold;
    text-decoration: underline;
  }
.image{
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
   
    
}

.image img{
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    transition: transform 0.5s ease-in-out;
}
.image img:hover {
    background: transparent;
    border-color: var(--color-bg-variant);
    box-shadow: 0 0 10px 5px var(--color-primary-variant);
    transform: scale(1.1);
    cursor: pointer;
}

.image img:nth-child(2){
    position: absolute;
    top: -10%;
    right: 0;
}

.RefImg{
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    
}

.RefImg img{
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    transition: transform 0.5s ease-in-out;
    
}
.RefImg img:hover {
    background: transparent;
    border-color: var(--color-bg-variant);
    box-shadow: 0 0 10px 5px var(--color-primary-variant);
    transform: scale(1.1);
    cursor: pointer;
}

.RefImg img:nth-child(2){
    position: absolute;
    top: -10%;
    right: 0;
    cursor: pointer;
}


     /* =================MEDIA QUERIES (MEDIUM DEVICES) ================*/

     @media screen and (max-width: 1024px) {
        .booking{
            margin: 4rem 2rem;
            margin-bottom: 10rem;
        }

    }

     /* =================MEDIA QUERIES (SMALL DEVICES) ================*/

     @media screen and (max-width: 600px) {
        .booking{
           margin-bottom: 10rem;
               
        }
        .Bokning{
            display:flex;
            flex-direction: column-reverse;
            margin-top: 2.5rem;
        }
        .Information{
            width: 100%;
        }

        .Steg{
            display:flex;
            flex-direction: column-reverse;
            margin-top: 2.5rem;
        }
        .InfoSteg{
            width: 100%;
        }

        .image{
            width: 100%;
            margin: 1.8rem 0;
        }
        .image img{
            height: 250px;
        }

        .RefImg{
            width: 100%;
            margin: 1.8rem 0;
        }
        .RefImg img{
            height: 250px;
        }
        
        
    }