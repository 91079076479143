.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 120px;
}

.headerContainer 
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading{
  color: #f1f1f1;
  
}

.uheading {
  color: red;
}

.product-card {
  
  width: 330px;
  height: 400px;
  margin: 20px;
  perspective: 1000px;
  display: inline-block;
}

.product-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.product-card-inner.flipped {
  transform: rotateY(180deg);
}

.product-card-front,
.product-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.product-card-front {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1b1b1b;
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.product-card-front img {
  max-width: 100%;
  max-height: 275px;
  margin-bottom: 10px;
}

.product-card-back {
  background-color: #1b1b1b;
  padding: 20px;
  transform: rotateY(180deg);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.product-card-back h2,
.product-card-back p {
  margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .product-card {
    flex-basis: calc(50% - 40px);
  }
}