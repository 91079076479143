
  

/* Namn och beskrivning */ 

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.form-input {
  padding: 10px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  font-size: 26px;
  width: 200px;
}
.text-container {
 margin-left: -75px;
 margin-top: -225px;
 margin-bottom: 75px;
 
}
.text-container h1 {
  color: #f1f1f1;
}

.ladda-knapp {
  background-color: #333;
  border: none;
  color: white;
  padding: 10px 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

/* Namn */ 

.namn-container {
  display: flex; 
  margin-left: 600px;
  margin-top: -350px;
  margin-right: 500px;
}
/* Profil bild */ 

.profilBild-container {
    display: flex; 
    flex-direction: column; 
    align-items: left;
    margin-left: 210px;
    margin-top: 100px;
    margin-bottom: 100px;
    
}
.profilBild-container img {
    border-radius: 20%;
    width: 500px;
    height: 625px;
    object-fit: cover;
    margin-top: 40px;
    
    }

/* Image container */
.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

/* Image styles */
.image-container img {
  width: calc(33.33% - 150px);
  height: 500px;
  object-fit: cover;
  margin: 5px;
  transition: all 0.2s ease-in-out;
}

/* Hover effect */
.image-container img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  margin: 20px;
  width: 300px;
  height: 400px;
  background-color: #292929;
  overflow: hidden;
  position: relative;
}

.card:hover .overlay {
  opacity: 1;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translateY(-50%);
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.overlay h2 {
  font-size: 24px;
  margin: 0;
  margin-bottom: 10px;
  color: #ffffff;
}

 .pBeskrivning {
  font-size: 26px;
  margin: 0;
  margin-bottom: 20px;
  color: #f1f1f1;
}

.overlay a {
  background-color: #f1f1f1;
  color: #f1f1f1;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.overlay a:hover {
  background-color: #1f1f1f;
  color: #fff;
}

.iconPort {
  color: #f1f1f1;
  margin-right: 1rem;
  transition: all 0.2s ease-in-out;
}

.iconPort:hover {
  transform: scale(1.5);
}
  
  @media screen and (max-width: 900px) {
    .card {
      width: 250px;
      height: 250px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .container {
      padding: 10px;
    }
  
    .card {
      width: 200px;
      height: 200px;
    }
  }





  /* =================MEDIA QUERIES (MEDIUM DEVICES) ================*/


@media screen and (max-width: 1700px) {


  .image-container img {
    width: calc(33.33% - 50px);
  }
 .text-container {
  margin-top: -250px;
  width: 700px;
 }
 .pBeskrivning {
  font-size: 22px;
 }
}


  


@media screen and (max-width: 1524px) {

  .profilBild-container {
    align-items: center;
    margin: 0;
  }
  .text-container {
    width: 80%;
    margin-top: 20px;
  }

  .namn-container {
    margin: 0;
    text-align: center;
    padding-left: 9rem;
    margin-left: 200px;
    
  }

  .image-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0;
   
  }

  .image-container img {
    width: 85%;
    margin-top: 15px;
    
    
  }
  
  .pBeskrivning {
    font-size: 20px;
  }
 
}
  

/* =================MEDIA QUERIES (SMALL DEVICES) ================*/

@media screen and (max-width: 600px) {
  .profilBild-container {
    align-items: center;
    margin: 0;
  }


  /*Namn och beskrivning */

  
  
  /* Namn */ 
  
  .text-container {
    width: 80%;
    margin-top: 20px;
  }




  .namn-container {
    margin: 0;
    text-align: center;
    padding-left: 5rem;
    margin-left: 100px;
  }
  .pBeskrivning {
    font-size: 15px;
  }


  /* ProjektBilder */ 

  .image-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0;
   
  }
  .image-container img {
    width: 85%;
    margin-top: 15px;
    
  }
  
}