.blog-post {
  display: grid;
  grid-template-columns: repeat(auto-fit); 
  
  
}
  
  /* Article styles */

  .article::before,
.article::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}




  .article {
    position: relative;
    padding: 5px;
    border-radius: 10px;
    background-color: #131313;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    margin-bottom: 300px;
    margin-left: 10px;
    margin-right: 55px;
    border: 1px solid black; 
    
    
    
    
    
    
  }
  
  .article img {
    border-radius: 20px;
    margin-bottom: 20px;
    margin-top: 40px;
    margin-left: 40px;
    
    
  }
  
  .article h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .article h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: auto;
    margin-top: -25px;
    color: #f1f1f1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    
  }
  .description5 {
    height: 1.5em; /* Adjust the desired height */
    overflow: hidden;
    
  }
  
  .description-content5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-size: 18px; /* Adjust the font size as needed */
    font-weight: 500;
    margin-bottom: 25px;
    color: #f1f1f1;
       
  }
  
  
  .article p {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #f1f1f1;
  }
  
  .article .date {
    position: absolute;
    top: 10px;
    right: 1px;
    font-size: 14px;
    font-weight: 400;
    color: #f1f1f1;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Modal styles */ 

  .modal2 {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #131313;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 450px;
    height: 300px;
  }
  
  .modal h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .modal p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #f1f1f1;
  }

  .read-more {
    padding: 10px 20px;
    background-color: #333;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    transition: background-color 0.3s ease;
  }
  .read-more:hover {
    background-color:#f1f1f1 ;
    color: #333;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 40px; /* Adjust the padding as needed */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .close {
    
    padding: 10px 20px;
    background-color: #333;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
  }
  .close:hover {
    background-color:#f1f1f1 ;
    color: #333;
  }
  
  
  
/* Divider */ 

.divider {
  border: none;
  border-top: 1px solid black;
  margin: 20px 0;
  
}
/*Rubrik */

.rubrik {
  text-align: center;
}


  /* Latest Articles */ 

  .latest-articles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin-left: 300px;
    margin-right: 300px;
  }
  
  .latest-articles .article:first-child {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  
  .latest-articles .article:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
  
  .latest-articles .article:last-child {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }
  
  .other-articles {
    display: none;
  }
  .flöde {
    width: 100%;
  }
  
  /* Responsive styles */

  @media screen and (max-width: 1800px) {

    .latest-articles {
      display: block;
      align-items: center;
      margin: 0;
    }
    .latest-articles .article {
      width: 45%;
      margin-bottom: 20px;
    }
    .article {
      display: block;
      align-items: center;
      margin: 0 auto;
    }
   
  }

  @media screen and (max-width: 600px) {
    .latest-articles {
      display: block;
      align-items: center;
      margin: 0;
    }
    .latest-articles .article {
      width: 88%;
      margin-bottom: 20px;
    }
    .article {
      display: block;
      align-items: center;
      margin: 0 auto;
    }
    .modal-content2{
      width: 80%;
      height: 45%;
    }

    .article img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }


  .other-articles .article {
    position: relative;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .other-articles .article img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .other-articles .article h2 {
    font-size: 20px;
    margin-bottom: 1px;
     
  }
  
  .other-articles .article h4 {
    font-size: 15px;

  }