.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  
  
}

.header-bg{
  background-color: rgba(0, 0, 0, 0.226);
  transition: 0.5s;
}


/*Portfolio dropdown style */

.portfolio-link {
  position: relative;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; /* Set the width to 100% to make the content appear next to each other */
  display: none;
  padding: 0;
  margin: 0;
  display: flex; /* Add flex display */
  flex-wrap: wrap; /* Wrap the content to the next line if needed */
  justify-content: flex-start; /* Align the content to the start of the container */
  margin-top: 0.5rem; /* Add margin-top to create space between the link and the content */
}
.dropdown-menu li {
  padding: 0.5rem 1rem;
  background-color: #eb000000;
  color: #000;
}
.portfolio-link:hover .dropdown-menu {
  display: block;
}
.dropdown-menu li:hover {
  background-color: #ffffff00;
  color: #ffffff00;
}


.nav-menu{
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.nav-menu li{
  padding: 0 1rem;
}

.nav-menu li:last-child ul.dropdown-menu {
  left: auto;
  right: 0;
}
nav li ul.dropdown-menu li:hover {
  background-color: #000;
  color: #fff;
}

.nav-menu li a{
  font-size: 1.2rem;
  font-weight: 500;
}

.hamburger{
  display: none;
}

@media screen and (max-width: 1040px) {
  .nav-menu{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100vh;
      background: rgb(29, 29, 29);
      position: absolute;
      top: 0;
      left: -100%;
      z-index: -3;
      transition: 0.3s;
      
  }

  .dropdown-menu {
    position: absolute;
    top: 75%;
    left: 0;
    width: 100%;
    display: none;
    margin: 0;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0.1rem;
    line-height: 0.5;
    transform: translateX(20%); /* Adjust the translateX value to move the content to the right */
  }
  .nav-menu.active .dropdown-menu li a {
    font-size: 29px; /* Adjust the font size to your desired value */
    
  }
  


  .nav-menu.active {
      left: 0;
  }

  .nav-menu li{
      padding: 1rem 0;
  }
  .nav-menu li a {
      font-size: 2rem;
  }

  .hamburger{
      display: initial;
  }
  
  
}