.container.contact__container{        
    display:inline;
}

.contact__options {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    padding-left: 23.5%;
}

.contact__option {
    padding: 1.5rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition)
}
.rubrikText{
    color: #f1f1f1;
}

.rubrikText:hover {
    color: #afaeae;
}

.contact__option:nth-child(1) {
    width: 333px;
    height: 286px;
  }

  .contact__option:nth-child(2) {
    width: 333px;
    height: 286px;
  }
  
  .contact__option:nth-child(3) {
    width: 333px;
    height: 286px;
  }

#InstagramAnan {
    background-image: url('../assets/ananBild.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

#Instagram {
    background-image: url('../assets/arreBild.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

#Messenger {
    background-image: url('../assets/Mässa.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-bg-variant);
    box-shadow: 0 0 10px 5px var(--color-primary-variant);
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 1.3rem;
    color: var(--color-white)
}
.contact__option h5 {
    color: var(--color-white);
}
.links{
    color: var(--color-bg-variant);
}
.message-sent {
    color: green;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
  }
/* ==================== FORM ==================*/

form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-top: 50px;
    padding-left: 35%;
    padding-right: 35%;
    padding-bottom: 50px;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0;
    background: transparent;
    border: none;
    border-bottom: 2px solid var(--color-bg-variant);
    resize: none;
    color: #f1f1f1;
  }

#mapcon.location{
    padding-left: 25%;
}

/* =================MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1770px) {
    .container.contact__container{
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .contact__options{
        
        margin: 0 0 0 2rem;
        padding: 5%;
    }

    form{
        padding: 150px;
        margin: 0;
        width: 100%;
    }
    
    #contact{
        margin: 0;
    }

    #mapcon.location{
        padding-left: 30%;
        width: 150vh;
    }
    
}

 /* =================MEDIA QUERIES (SMALL DEVICES) ================*/

 @media screen and (max-width: 600px) {
    .contact__options{
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    form{
        margin-top: 15px;
        padding: 15px;
    }
    
    #contact{
        margin: 0;
    }

    #mapcon.location{
        padding-left: 20%;
        width: 100%;
    }

    
}