.footer{
    width: 100%;
    bottom: 1;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    background-color: #0c0c0c;
    position: absolute;
    z-index: 1000;
}

.footer-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 200px;
}

.left{
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.location{
    display: flex;
}

.location p{
    line-height: 30px;
}

.right{
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.social{
    margin-top: 1rem;
}

h4{
    font-size: 1rem;
    padding-bottom: 0.5rem;
    color: white;
}

.adress{
    font-size: 1rem;
    color: white;
}

@media screen and (max-width: 600px){
    .footer-container{
        height: 40%;
        grid-template-columns: 1fr;  
        grid-gap: 20px;
    }
}

@media screen and (max-width: 480px){
    .footer-container{
        height: 40%;
        grid-template-columns: 1fr;  
        grid-gap: 20px;
    }
}