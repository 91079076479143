.faq {
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 20px;
}

.faq h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 40px;
  color: #f1f1f1;
}

.faq-item {
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.faq-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #1b1b1b;
  cursor: pointer;
}

.faq-item-header:hover {
  background-color: #222222;
}

.faq-item-header.active {
  background-color: #363636;
}

.faq-item-body {
  padding: 0px;
  background-color: #0c0c0c;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.faq-item-body p {
  font-size: 20px;
}

.faq-item-body.active {
  max-height: 500px;
  transition: max-height 0.3s ease-in-out;
}

.toggle-icon {
  font-size: 24px;
  font-weight: 600;
}