@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg:#131313;
  --color-bg-variant: rgb(29,29,29);
  --color-primary: #f1f1f1;
  --color-primary-variant: rgba(83, 82, 82, 0.616);
  --color-white: #fff;
  --color-black: #333;
  --color-light: rgba(255, 255, 255, 0.6);

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%
}

html {
  scroll-behavior: smooth;
}

/*::-webkit-scrollbar{
  display: none;
}*/

body {
  font-family: 'Saira Condensed', sans-serif;
  background: var(--color-bg);
  line-height: 1.7;
}

/* =================== GENERAL STYLES ================*/

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h2,h3,h4,h5{
  font-weight: 500;
  color: var(--color-primary);
  text-decoration: none;
}

h1{
  font-size: 2.5rem; 
  color: (var(--color-primary))
}
h2{
  font-size: 2rem; 
}
h5{
  font-size: 1.2rem; 
}


section {
  margin-top: 8rem;
}

section > h2, section > h5 {
  text-align: center;
  color: var(--color-primary);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 0;
}

.text-light{
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

p {
  color: var(--color-primary);
}

a:hover {
  color: var(--color-primary-variant);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.7rem 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-black);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

ul{
  list-style-type: none;
}

/* =================MEDIA QUERIES==============*/

@media  screen and (max-width: 600px) 
{
  .container{
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }
  
}

@media  screen and (max-width: 1024px) 
{
  .container{
    width: var(--container-width-md);
  }

  section{
    margin-bottom: 6rem;
  }
  
}