.hero{
    width: 100%;
    height: 100vh;
    position: relative;
}



.hero-home{
    width: 100%;
    height: 60vh;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);
}

.hero-mid{
    width: 100%;
    height: 60vh;
    position: relative;
}


img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    
}
video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    
}

.hero-text {
    position: absolute;
    animation-name: flyin;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    opacity: 0;
    left: 50.5%;
    top: 45%;
    transform: translateX(-50%);
  }

  @keyframes flyin {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(-50%);
      opacity: 1;
    }
  }

.hero-text h1{
    font-size: 4.5rem;
    font-weight: 800;
    padding: 0.5rem 0 1rem 0;
    background: var(--color-white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-text .show{
    text-decoration: none;
    background: linear-gradient(to right, #333, #131313);
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.7rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: var(--color-white);
    margin-left: 35%;
    transition: all 0.3s ease-in-out;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    position: relative;
    top: -20px;
    
    
}

.hero-text .show:hover {
  background: linear-gradient(to right, #131313, #333);
  color: var(--color-white);
  border: 1px solid var(--color-white);
  box-shadow: 2px 2px 8px rgba(0,0,0,0.4);
}


.hero-text .hide{
    display: none;
}

@media screen and (max-width: 1700px){
    .hero-text h1 {
        padding: 0px 30px;
        font-size: 3.8rem;
        margin-top: -110px;
    }
    .hero-text .show{
        margin-left: 13.5rem;
        padding: 0.8rem 1.1rem;
        border-radius: 6px;
        font-size: 2rem;
        position: relative;
        top: -5px;
    }

   


}

@media screen and (max-width: 600px){
    .hero-text h1 {
        margin-left: 2rem;
        padding: 0px 30px;
        font-size: 3rem;
        margin-top: -110px;
    }
    .hero-text .show{
        margin-left: 5.5rem;
        padding: 0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
    }


}

@media screen and (max-width: 475px){
    .hero-text h1 {
        margin-left: 0.1rem;
        padding: 0px 30px;
        font-size: 3rem;
        margin-top: -110px;
    }
    .hero-text .show{
        margin-left: 3.7rem;
        padding: 0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
    }


}