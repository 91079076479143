.image-grid {
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  
  
}

.rubrik {
  color: #f1f1f1;
  
}


.text-grid {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f1f1f1;
  

}



.container {
  margin-top: 0;
  
  
}
.image-card::before,
.image-card::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  
}




.image-card {
  position: relative;
  width: 550px;
  height: 750px;
  margin: 20px;
  transition: transform 0.5s ease-in-out;
  transform-style: preserve-3d;
  cursor: pointer;
  border: 1px solid #131313;  
  border-radius: 10px;
  
}

.image-card:hover {
  background: transparent;
  border-color: var(--color-bg-variant);
  box-shadow: 0 0 10px 5px var(--color-primary-variant);
  transform: scale(1.05);
}
  
  

.image-card.active {
  transform: rotateY(180deg);
  
}

.image-card .front-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  
  
}

.image-card .back-face {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #131313;
  color: #131313;
  font-size: 2rem;
  padding: 20px;
  text-align: center;
  
}

.text-field {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 0;
  
  
}
.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.arre-text {
  position: absolute;
  right: 61%;
  margin-top: -70px;
}
.arre-text a {
  color: #f1f1f1;
  font-size: 3.5rem;
}

.arre-text a:hover {
  color: #333;
}

.anan-text {
  position: absolute;
  right: 28%;
  margin-top: -70px;
}

.anan-text a {
  color: #f1f1f1;
  font-size: 3.5rem;
}

.anan-text a:hover {
  color: #333;
}


.image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #131313;
  border-radius: 10px;
}

.image-card p {
  margin: 0;
}




@media only screen and (max-width: 1800px) {
  .image-card {
    width: 400px;
    height: 500px;
  }
  .image-card .back-face {
    width: 300px;
    height: 400px;
    margin: 0px auto;
    font-size: 1.1rem;
  }
  .anan-text a {
    color: #f1f1f1;
    font-size: 2rem;
    
  }
  .arre-text a {
    color: #f1f1f1;
    font-size: 2rem;
    
  }

  .arre-text {
    
    left: 34%;
    margin-top: -70px;
    padding-right: 10rem;
    
  }

  .anan-text {
    padding-left: 3rem;
    left: 55%;
    margin-top: -70px;
    
  }
  

}


@media only screen and (max-width: 1450px) {

.arre-text {
  left: 30%;
}

}

@media only screen and (max-width: 1200px) {

  .arre-text {
    left: 25%;
  } 
}
@media only screen and (max-width: 1000px) {

  .arre-text {
    left: 20%;
  }
}
@media only screen and (max-width: 750px) {

  .arre-text {
    left: 15%;
  }
}


/* Media queries for small devices */

@media only screen and (max-width: 600px) {
  .image-grid {
    flex-direction: column;
    align-items: center;
  }
  
  .image-card {
    width: 250px;
    height: 400px;
    margin: 20px auto;
  }

  .image-card .back-face {
    width: 250px;
    height: 400px;
    margin: 0px auto;
    font-size: 1rem;
  }
  .anan-text a {
    color: #f1f1f1;
    font-size: 2rem;
  }
  .arre-text a {
    color: #f1f1f1;
    font-size: 2rem;
    
  }

  .arre-text {
    position: absolute;
    left: 35%;
    margin-top: -505px;
  }

  .anan-text {
    position: absolute;
    left: 24%;
    margin-top: -65px;
  }
  

    
}

@media only screen and (max-width: 475px) {

  .arre-text {
    position: absolute;
    left: 35%;
    margin-top: -505px;
  }

  .anan-text {
    position: absolute;
    left: 18%;
    margin-top: -65px;
  }

}