.about-container{
  margin-left: 70%;
  margin-top: -830px;
  width: 20%;
  text-align: center;
  padding-bottom: 1rem;
  z-index: 2;
}

.about-container h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #f1f1f1;
  margin-bottom: 1rem;
}

.about-container p {
  font-size: 1.1rem;
  line-height: 1.5;
  text-align: center;
  color: #f1f1f1;
  padding-bottom: 1rem;
}

.container {
  max-width: 124rem;
  padding: 0;
  margin: 0 auto;
}


.swiper_container {
  height: 50rem;
  right: 30%;
}

.swiper-slide {
  padding-top: 1rem;
  width: 37rem !important;
  height: 42rem !important;
}

.swiper-slide img {
  width: 37rem !important;
  height: 42rem !important;
  border-radius: 0.5rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image img {
  width: 100%;
  height: auto;
}

.swiper-pagination {
  width: 15rem;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--color-light);
}

@media screen and (max-width: 1024px){
  .about-container{
      margin: 1rem 11rem;
      width: 30rem;
  }
}

@media screen and (max-width: 600px){
  .about-container{
      margin: 1rem 2rem;
      width: 21rem;
  }
}

@media (max-width: 600px) {
  .swiper_container {
    height: 47rem;
    left: 2%;
  }
}

@media (max-width: 1024px) {
  .swiper_container {
    height: 47rem;
    left: 1%;
  }
}